import React from "react"
import { graphql } from "gatsby"
import { useQueryParam, NumberParam, StringParam } from "use-query-params"

import Img from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import theme from "../themes/theme"
import * as EmailValidator from "email-validator"

import { PageLayout } from "../components/PageLayout"

import {
  // HeroGrid,
  // HeroSection,
  HeroHeadline,
  HeroSubHead,
  // HeroExtra,
  // HeroButton,
} from "../components/HeroSection"

import { FormButtonSecondary } from "../components/Buttons"

import {
  RowSection,
  TwoColumnSection,
  TwoColumnSectionLeft,
  TwoColumnSectionRight,
} from "../components/SectionBlocks"

import { FaRegUser, FaMoneyCheckAlt } from "react-icons/fa"
import {
  AiOutlineMail,
  AiOutlineMobile,
  AiOutlineClockCircle,
  AiOutlineQuestionCircle,
} from "react-icons/ai"
import { GoDeviceMobile } from "react-icons/go"
import { FiTarget } from "react-icons/fi"
import { BiCurrentLocation, BiHelpCircle, BiStopwatch } from "react-icons/bi"
import { BsPersonCheck } from "react-icons/bs"
import { IoMaleFemale } from "react-icons/io5"

// import Bio from "../components/bio"
// import Layout from "../components/layout"
import SEO from "../components/seo"
import { Fragment } from "react"
// import { render } from "react-dom"

const PageClubBunker = ({ data, location }) => {
  // const [fname, setFname] = useQueryParam("f", StringParam)
  // const [email, setEmail] = useQueryParam("e", StringParam)
  // const [mobile, setMobile] = useQueryParam("m", StringParam)

  return (
    <PageLayout>
      <SEO
        title="Club Bunker - Your fitness goals"
        ogImage={data?.socialImage?.childImageSharp?.fixed.src}
        description="Helping Club Bunker members achieve their best"
        url={location.origin + location.pathname}
      />

      <div className="relative w-full z-10">
        <div className="absolute inset-0 -z-20">
          <Img
            fluid={data?.heroImageMitchAlex?.childImageSharp?.fluid}
            alt="Club Bunker"
            objectFit="cover"
            className="md:-z-10 hidden md:block md:opacity-30 h-full"
          />

          {/* <GatsbyImage
            image={getImage(data?.heroImageMitchAlex2)}
            className="md:-z-10 hidden md:block md:opacity-30 h-full"
          /> */}
        </div>
        <div className="z-20">
          <div className="w-full flex flex-col md:flex-row-reverse px-2">
            <div className="">
              <div className="md:mt-4 md:mr-24">
                <Img
                  className="block md:hidden mt-2 mb-0"
                  fluid={data?.clubBunkerLogoBlack?.childImageSharp?.fluid}
                  width="100%"
                  alt="Club Bunker logo"
                />
                <h1 className="font-bold text-4xl mt-4 md:mt-10 text-LPblack text-center md:text-left font-serif">
                  Get your FREE consult
                </h1>

                <p className="font-normal text-xl text-LPblack text-center md:text-left font-serif">
                  Starting at a new gym and aren't really sure where to begin?
                </p>
                <p className="font-normal text-xl text-LPblack text-center md:text-left font-serif">
                  We know exactly how you feel! We are more than happy to spend time helping you get
                  your questions answered.
                </p>
                <ul className="md:list-disc list-inside font-normal text-xl text-LPblack text-center md:text-left font-serif">
                  <li>Have a previous injury you're concerned about?</li>
                  <li>Tried a gym or trainer before and it didn't work out?</li>
                </ul>

                <p className="font-medium text-xl text-LPblack text-center md:text-left font-serif">
                  We know exactly how you feel! We are more than happy to spend time helping you get
                  your questions answered.
                </p>
              </div>
            </div>
            <div className="">
              <div className="m-2 md:m-8 bg-primaryBlueNormal rounded-xl">
                <div className="p-4 md:p-8 text-center">
                  <p className="font-bold text-4xl text-center text-LPwhite text-center md:text-left font-serif">
                    Start Getting Leaner, Fitter, Stronger Today
                  </p>
                  <p className="font-normal text-base text-center text-LPwhite text-center md:text-left font-serif">
                    As a new <strong>Club Bunker</strong> member you are eligible for a{" "}
                    <i>FREE CONSULT</i> with one of our qualified Coaches.
                  </p>
                  <p className="font-normal text-base text-center text-LPwhite text-center md:text-left font-serif">
                    To ensure we deliver the very best gym and fitness experience, we'd love to get
                    to know you a little better.
                  </p>
                  <p className="font-normal text-base text-center text-LPwhite text-center md:text-left font-serif">
                    Simply book in for your free consultation.
                  </p>
                  <p className="font-normal text-base text-center text-LPwhite text-center md:text-left font-serif">
                    There are <strong>no obligations</strong> other than we request if you are
                    unable to make the appointment, please let us know beforehand.
                  </p>
                  <QualificationForm />
                  <p></p>
                  <p className="font-normal text-sm text-center text-LPwhite text-center md:text-left font-serif">
                    Your information is completely safe. We do not sell your contact details or
                    share your personal information.
                  </p>
                  <p className="font-normal text-sm text-center text-LPwhite text-center md:text-left font-serif">
                    We also really hate spam too!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default PageClubBunker

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
    handstandImage: file(absolutePath: { regex: "/mitch-handstand-dark-1920x874.jpg/" }) {
      childImageSharp {
        fixed(width: 1200, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    heroImageMitchAlex: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-1920x874.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 1920, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    heroImageMitchAlex2: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-1920x874.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          aspectRatio: 1.77
          formats: [AVIF, AUTO, WEBP]
          placeholder: BLURRED
        )
      }
    }

    clubBunkerLogo: file(absolutePath: { regex: "/ClubBunkerLogo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    clubBunkerLogoBlack: file(absolutePath: { regex: "/ClubBunkerLogo-black.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    heroImage: file(absolutePath: { regex: "/mitch-squat-light-1920x874.jpg/" }) {
      childImageSharp {
        fixed(width: 1920, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    socialImage: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-social-1200x628.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 1200, height: 628, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

class BigGoalComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onBigGoalChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_9">
            <FiTarget color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_9"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_9"
            aria-label="What is your big goal?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.goalBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              What is your big goal right now?
            </option>
            <option value="16">Get stronger and more muscular</option>
            <option value="17">Get leaner and more defined</option>
            <option value="18">Get flexible and move better</option>
          </select>
        </div>
      </div>
    )
  }
}

class FitnessSituationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onFitnessSituationChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_10">
            <BiCurrentLocation color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_10"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_10"
            aria-label="What is your fitness situation"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.fitnessSituationBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              What best describes your current fitness situation?
            </option>
            <option value="19">
              I previously achieved my goal but have lost my way and want help to get back to where
              I was
            </option>
            <option value="20">I am partway to achieving my goal but now I&#039;m stuck</option>
            <option value="21">
              I&#039;ve always struggled with my goal and have no idea what to do
            </option>
          </select>
        </div>
      </div>
    )
  }
}

class WhenStartComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onWhenStartChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_11">
            <AiOutlineClockCircle color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_11"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_11"
            aria-label="What is your fitness situation"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.whenStartBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              When are you looking to start?
            </option>
            <option value="22">Now</option>
            <option value="23">Later</option>
          </select>
        </div>
      </div>
    )
  }
}

class WhatExtraHelpComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onWhatExtraHelpChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_12">
            <BiHelpCircle color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <textarea
            type="text"
            name="custom_12"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_12"
            cols="50"
            rows="5"
            aria-label="What do you need the most help with right now?"
            placeholder="What do you need the most help with right now?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.extraHelpBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}></textarea>
        </div>
      </div>
    )
  }
}

class GoalTimelineComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onGoalTimelineChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_13">
            <BiStopwatch color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_13"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_13"
            aria-label="What is your goal timeline"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.goalTimelineBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              What do you believe is a realistic timeline to achieve your goal?
            </option>
            <option value="24">12 months or greater</option>
            <option value="25">6 - 12 months</option>
            <option value="26">Less than 6 months</option>
          </select>
        </div>
      </div>
    )
  }
}

class DecisionMakerComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onDecisionMakerChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_14">
            <BsPersonCheck color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_14"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_14"
            aria-label="Are you the decision maker?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.decisionMakerBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              Are you the financial decision maker?
            </option>
            <option value="27">Yes</option>
            <option value="28">No, I need to consult with my partner / significant other</option>
          </select>
        </div>
      </div>
    )
  }
}

class FinancialSituationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onFinancialSituationChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_15">
            <FaMoneyCheckAlt color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_15"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_15"
            aria-label="What best describes your current financial situation?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.financialSituationBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              What best describes your financial situation?
            </option>
            <option value="29">
              I have the financial resources now to invest in my health and fitness
            </option>
            <option value="30">
              I am broke and have no credit card to invest in my health and fitness
            </option>
          </select>
        </div>
      </div>
    )
  }
}

class PreferredTrainerGenderComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onPreferredTrainerGenderChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_17">
            <IoMaleFemale color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_17"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_17"
            aria-label="Do you have a preferred trainer gender?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.preferredTrainerGenderBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              Do you have a preferred trainer gender
            </option>

            <option value="33">No preference</option>
            <option value="31">Male</option>
            <option value="32">Female</option>
          </select>
        </div>
      </div>
    )
  }
}

class AnythingElseToShareComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onAnythingElseToShareChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_16">
            <AiOutlineQuestionCircle color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <textarea
            type="text"
            name="custom_16"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_16"
            cols="50"
            rows="5"
            aria-label="Is there anything else you feel is important and want to share?"
            placeholder="Is there anything else you feel is important and want to share?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.anythingElseToShareBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}></textarea>
        </div>
      </div>
    )
  }
}

class UserNameComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onUserNameChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userName">
            <FaRegUser color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <input
            type="text"
            name="first_name"
            placeholder={"First name ..."}
            defaultValue={this.props.firstValue}
            // defaultValue={this.props.fname ? this.props.fname : ""}
            // value=""
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="userName"
            aria-label="First Name"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.userBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}
          />
        </div>
      </div>
    )
  }
}

class UserEmailComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
  }

  handleValueChange(e) {
    this.props.onUserEmailChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          border: "0px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userEmail">
            <AiOutlineMail color="white" fontSize="2.3rem" padding="5px" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <input
            type="text"
            className="js_kartra_santitation"
            data-santitation-type="email"
            placeholder="Email address ..."
            // defaultValue={this.props.email ? this.props.email : ""}
            defaultValue={this.props.firstValue}
            name="email"
            id="userEmail"
            aria-label="Email Address"
            onChange={this.handleValueChange}
            // style={{ border: "1px solid white", padding: "5px" }}
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.emailBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
          />
        </div>
      </div>
    )
  }
}

class UserMobileComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
  }

  handleValueChange(e) {
    this.props.onUserMobileChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          border: "0px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userMobile">
            <GoDeviceMobile color="white" fontSize="2.3rem" padding="5px" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <input
            type="text"
            className="js_kartra_santitation"
            data-santitation-type="numeric"
            placeholder="Mobile phone ..."
            // defaultValue={this.props.mobile ? this.props.mobile : ""}
            name="phone"
            id="userMobile"
            aria-label="Mobile Phone"
            onChange={this.handleValueChange}
            // style={{ border: "1px solid white", padding: "5px" }}
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.mobileBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
          />
        </div>
      </div>
    )
  }
}

class QualificationForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleUserNameChange = this.handleUserNameChange.bind(this)
    this.handleUserEmailChange = this.handleUserEmailChange.bind(this)
    this.handleUserMobileChange = this.handleUserMobileChange.bind(this)
    this.handleBigGoalChange = this.handleBigGoalChange.bind(this)
    this.handleFitnessSituationChange = this.handleFitnessSituationChange.bind(this)
    this.handleWhenStartChange = this.handleWhenStartChange.bind(this)
    this.handleWhatExtraHelpChange = this.handleWhatExtraHelpChange.bind(this)
    this.handleGoalTimelineChange = this.handleGoalTimelineChange.bind(this)
    this.handleDecisionMakerChange = this.handleDecisionMakerChange.bind(this)
    this.handleFinancialSituationChange = this.handleFinancialSituationChange.bind(this)
    this.handlePreferredTrainerGenderChange = this.handlePreferredTrainerGenderChange.bind(this)
    this.handleAnythingElseToShareChange = this.handleAnythingElseToShareChange.bind(this)
    this.handleSubmitClick = this.handleSubmitClick.bind(this)

    this.state = {
      validName: false,
      userBorder: "none",
      validEmail: false,
      emailBorder: "none",
      validMobile: false,
      mobileBorder: "none",
      validGoal: false,
      goalBorder: "none",
      validFitnessSituation: false,
      fitnessSituationBorder: "none",
      validWhenStart: false,
      whenStartBorder: "none",
      validExtraHelp: true,
      extraHelpBorder: "none",
      validGoalTimeline: false,
      goalTimelineBorder: "none",
      validDecisionMaker: false,
      decisionMakerBorder: "none",
      validFinancialSituation: false,
      financialSituationBorder: "none",
      validPreferredTrainerGender: false,
      preferredTrainerGenderBorder: "none",
      vallidAnythingElseToShare: false,
      anythingElseToShareBorder: "none",
      firstName: "",
      email: "",
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("FirstName"))
        this.setState({
          firstName: localStorage.getItem("FirstName"),
          validName: true,
          userBorder: "2px solid green",
        })
      if (localStorage.getItem("Email"))
        this.setState({
          email: localStorage.getItem("Email"),
          validEmail: true,
          emailBorder: "2px solid green",
        })
    }
  }

  handleSubmitClick(e) {
    if (typeof window !== "undefined") {
      localStorage.setItem("FirstName", this.state.firstName)
      localStorage.setItem("Email", this.state.email)
    }
  }

  handleUserNameChange(e) {
    if (e.length > 2) {
      this.setState({ validName: true, userBorder: "2px solid green", firstName: e })
    } else {
      this.setState({ validName: false, userBorder: "2px solid red" })
    }
  }

  handleUserEmailChange(e) {
    if (EmailValidator.validate(e)) {
      this.setState({ validEmail: true, emailBorder: "2px solid green", email: e })
    } else {
      this.setState({ validEmail: false, emailBorder: "2px solid red" })
    }
  }

  handleUserMobileChange(e) {
    if (e.length > 9) {
      this.setState({ validMobile: true, mobileBorder: "2px solid green" })
    } else {
      this.setState({ validMobile: false, mobileBorder: "2px solid red" })
    }
  }

  handleBigGoalChange(e) {
    if (e > 0) {
      this.setState({ validGoal: true, goalBorder: "2px solid green" })
    } else {
      this.setState({ validGoal: false, goalBorder: "2px solid red" })
    }
  }

  handleFitnessSituationChange(e) {
    if (e > 0) {
      this.setState({ validFitnessSituation: true, fitnessSituationBorder: "2px solid green" })
    } else {
      this.setState({ validFitnessSituation: false, fitnessSituationBorder: "2px solid red" })
    }
  }

  handleWhenStartChange(e) {
    if (e > 0) {
      this.setState({ validWhenStart: true, whenStartBorder: "2px solid green" })
    } else {
      this.setState({ validWhenStart: false, whenStartBorder: "2px solid red" })
    }
  }

  handleWhatExtraHelpChange(e) {
    if (e.length >= 0) {
      this.setState({ validExtraHelp: true, extraHelpBorder: "2px solid green" })
    } else {
      this.setState({ validExtraHelp: false, extraHelpBorder: "2px solid red" })
    }
  }

  handleGoalTimelineChange(e) {
    if (e > 0) {
      this.setState({ validGoalTimeline: true, goalTimelineBorder: "2px solid green" })
    } else {
      this.setState({ validGoalTimeline: false, goalTimelineBorder: "2px solid red" })
    }
  }

  handleDecisionMakerChange(e) {
    if (e > 0) {
      this.setState({ validDecisionMaker: true, decisionMakerBorder: "2px solid green" })
    } else {
      this.setState({ validDecisionMaker: false, decisionMakerBorder: "2px solid red" })
    }
  }

  handleFinancialSituationChange(e) {
    if (e > 0) {
      this.setState({ validFinancialSituation: true, financialSituationBorder: "2px solid green" })
    } else {
      this.setState({ validFinancialSituation: false, financialSituationBorder: "2px solid red" })
    }
  }

  handlePreferredTrainerGenderChange(e) {
    if (e > 0) {
      this.setState({
        validPreferredTrainerGender: true,
        preferredTrainerGenderBorder: "2px solid green",
      })
    } else {
      this.setState({
        validPreferredTrainerGender: false,
        preferredTrainerGenderBorder: "2px solid red",
      })
    }
  }

  handleAnythingElseToShareChange(e) {
    if (e.length >= 0) {
      this.setState({
        vallidAnythingElseToShare: true,
        anythingElseToShareBorder: "2px solid green",
      })
    } else {
      this.setState({
        vallidAnythingElseToShare: false,
        anythingElseToShareBorder: "2px solid red",
      })
    }
  }

  render() {
    return (
      <div>
        <form
          method="post"
          action="https://app.kartra.com/process/add_lead/EhjQ8txHlZa4"
          target="_top"
          className="form_class_EhjQ8txHlZa4 optin_form_for_video js_kartra_trackable_objec"
          data-kt-type="optin"
          data-kt-value="EhjQ8txHlZa4"
          data-kt-owner="Brl61K8g"
          acceptCharset="UTF-8">
          <div>
            <UserNameComponent
              onUserNameChange={this.handleUserNameChange}
              userBorder={this.state.userBorder}
              firstValue={this.state.firstName}
            />
            <UserEmailComponent
              onUserEmailChange={this.handleUserEmailChange}
              emailBorder={this.state.emailBorder}
              firstValue={this.state.email}
            />
            {/* <UserMobileComponent
              onUserMobileChange={this.handleUserMobileChange}
              mobileBorder={this.state.mobileBorder}
            /> */}
          </div>
          <div>
            <input
              type="hidden"
              id="custom_6"
              name="custom_6"
              className="js_kartra_santitation"
              data-santitation-type="name"
              aria-label="Preferred Gym"
              value="Club Bunker Newstead"
            />
          </div>

          <div style={{ marginLeft: "15px", marginRight: "15px" }}>
            <FormButtonSecondary
              style={{ alignItems: "center", justifyContent: "center" }}
              type="submit"
              enabled={this.state.validName && this.state.validEmail}
              onClick={this.handleSubmitClick}>
              Schedule Free Consultation
            </FormButtonSecondary>
          </div>
        </form>
      </div>
    )
  }
}
